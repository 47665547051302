<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { SET_CONNECTED } from "@/store";

export default {
  beforeCreate() {
    window.addEventListener("offline", () => {
      this.$store.commit(SET_CONNECTED, false);
    });
    window.addEventListener("online", () => {
      this.$store.commit(SET_CONNECTED, true);
    });
  },
};
</script>
